* {
  box-sizing: border-box;
  font-family: "Baloo Tamma 2", cursive;
}

html,
body {
  margin: 0;
  padding: 0;
  text-align: center;
  background: #cccccc;
}

body {
  padding: 10px;
}
