.popup-window-bg {
  overflow-y: auto;
  display: grid;
  position: fixed;
  place-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);

  animation: popup-in 0.3s ease-in-out;
}
@keyframes popup-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.popup-window {
  width: 100%;
  max-width: 500px;
  height: auto;
  padding: 30px;
  background: #ffffff;
  transition: all 0.5s ease-in-out;
  border-radius: 1.5em;
  /* transform: scale(0, 0); */
}

.popup-window h1 {
  margin: 0;
  font-size: 1.5em;
  text-align: center;
  color: #777777;
}

.popup-window p {
  margin: 30px 0 40px;
  text-align: center;
}

.popup-btn {
  cursor: pointer;
  padding: 1em 1em 0.85em;
  border: none;
  border-radius: 10px;
  outline: none;
  background: #333333;
  font-size: 13px;
  line-height: 13px;
  color: #ffffff;
}

.popup-btn:hover {
  background: #888888;
}
